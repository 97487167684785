import { Link } from "gatsby"
import React from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import { FaBoxes, FaDesktop, FaSearch, FaWrench } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"


const IndexPage = () => (
  <Layout>
    <SEO title="Digital Marketing Services" />
    <Container>
      <h1 className='text-center my-5'>Digital Marketing Services</h1>
      <p className="text-center">Our approach to digital marketing is a comprehensive plan. Each of our services are designed to compliment each other and take your business one step further to online results! </p>
      <Row className="mw-100 justify-content-center mx-0 my-5">
        <Col xl={12}>
          <Card className="shadow p-4 mt-3 mb-5 bg-light bg-gradient">
            <Row className="align-items-center">
              <Col xl={2}>
                <div className="mx-auto border rounded-circle p-4 mb-4 bg-white shadow" style={{ width: '82px', height: '82px' }}>
                  <FaBoxes className='text-prim-green' size='2em' />
                </div>
              </Col>
              <Col className="text-center">
                <Card.Title className="text-center">$0 Upfront Monthly Website &amp; Hosting Package</Card.Title>
                <span class="badge badge-dark bg-prim-green">Popular</span>
                <Card.Body>
                  <div className='d-flex align-items-center mh-110px'>
                    <p>Businesses rely on a website partner, someone who can answer questions and help make tough digital decisions. From $0 upfront and a low monthly fee, this service takes the headache out of your web design, hosting, and maintenance offering you piece of mind and stability on the web!</p>
                  </div>
                  <Link to="/services/monthly-website-packages/" className="btn btn-primary em-bg-primary-blue border-0 px-4">about monthly website services</Link>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={4} md={6}>
          <Card className="shadow p-4 mt-3 mb-5 bg-light bg-gradient">
            <div className="mx-auto border rounded-circle p-4 mb-4 bg-white shadow" style={{ width: '82px', height: '82px' }}>
              <FaDesktop className='text-prim-green' size='2em' />
            </div>
            <Card.Title className="text-center">Web Design &amp; Development</Card.Title>
            <Card.Body>
              <div className='d-flex align-items-center h-110px'>
                <p>Websites are the digital face of any business. They're the foundation of success for all things digital!</p>
              </div>
              <Link to="/services/web-design/" className="btn btn-primary em-bg-primary-blue border-0 d-block mx-auto">about website services</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} md={6}>
          <Card className="shadow p-4 mt-3 mb-5 bg-light bg-gradient">
            <div className="mx-auto border rounded-circle p-4 mb-4 bg-white shadow" style={{ width: '82px', height: '82px' }}>
              <FaWrench className='text-prim-green' size='2em' />
            </div>
            <Card.Title className="text-center">Web Hosting &amp; Maintenance</Card.Title>
            <Card.Body>
              <div className='d-flex align-items-center h-110px'>
                <p>With a beautiful website in hand, we'll host it in the cloud for all your customers to see!</p>
              </div>
              <Link to="/services/hosting/" className="btn btn-primary em-bg-primary-blue border-0 d-block mx-auto">about hosting services</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} md={6}>
          <Card className="shadow p-4 mt-3 mb-5 bg-light bg-gradient">
            <div className="mx-auto border rounded-circle p-4 mb-4 bg-white shadow" style={{ width: '82px', height: '82px' }}>
              <FaSearch className='text-prim-green' size='2em' />
            </div>
            <Card.Title className="text-center">Search Engine Optimization</Card.Title>
            <Card.Body>
              <div className='d-flex align-items-center h-110px'>
                <p>Drive traffic to your business's website by helping the search engines understand its contents.</p>
              </div>
              <Link to="/services/seo/" className="btn btn-primary em-bg-primary-blue border-0 d-block mx-auto">about seo services</Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage